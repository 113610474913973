<template>
  <teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0"
      enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-show="showProfile" ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
        <div class="flex items-start justify-center min-h-screen pt-20 text-center">
          <transition enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95" enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200" leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95">
            <div style="max-width: 400px" class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
              role="dialog" ref="modal" aria-modal="true" aria-labelledby="modal-headline">
                <Icon
                  class="float-right"
                  icon="ri:close-circle-line"
                  color="gray"
                  style="margin-top: -25px; margin-right: -25px; cursor: pointer;"
                  height="36"
                  @click="closeModal"
                />
              <div class="">
                <div v-if="joinID && (groups && (JSON.stringify(groups) === '{}' || (!groups[joinID])))">
                  <h3 class="mt-5 mb-5 text-lg font-medium text-gray-900 dark:text-white">Inivitation to the group</h3>
                  <div class="text-xl text-center"> {{ joinName }}</div>
                  <button v-if="joinID.length > 0"
                    class="mt-5 mb-5 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="
                      $store.dispatch('joinGroup', {
                        id: joinID,
                        uid: eyedleuser.uid,
                        name: eyedleuser.data.name || eyedleuser.data.email,
                        stats: stats,
                      }).then(() => {
                        $notify({ group: 'foo', title: 'Success', text: 'You have joined the group ' + joinName + '!' },
                        5000
                      );
                        $router.replace('/')
                        closeModal();
                      })">
                    Join group
                  </button>
                </div>

                <div v-else-if="!showInvite && loggedIn && !joinID">
                  <h3 class="mb-1 text-lg font-medium text-gray-900 dark:text-white">Create a new group</h3>
                  <div class="mb-2">
                    <input v-model="newgroup"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Group name" />
                  </div>
                  <button v-if="newgroup.length > 0"
                    class="mt-1 mb-5 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="
                      $store.dispatch('createGroup', {
                        uid: eyedleuser.uid,
                        name: newgroup,
                        stats: stats,
                        names: (eyedleuser.data && eyedleuser.data.name) ? { [eyedleuser.uid]: eyedleuser.data.name } : { [eyedleuser.uid]: eyedleuser.data.name },
                      }).then(() => {
                        newgroup = '';
                        showInvite = true;
                      })">
                    Save
                  </button>
                </div>

                <div v-if="!showInvite && loggedIn && groups && (JSON.stringify(groups) !== '{}')">
<!--                   <div class="mb-10 mt-10 text-center text-xl">
                    OR
                  </div>
 -->                  <div class="mt-4 text-center">
                  Already a member of a group?
                  <p @click="showInvite = true" style="color: #07C157; cursor: pointer;" class="forgot-password">
                    Invite more friends!
                  </p>
                </div>
                </div>

                <div v-if="showInvite && loggedIn && groups && (JSON.stringify(groups) !== '{}')">
                  <Icon
                    class="float-left pr-2"
                    icon="material-symbols:keyboard-backspace"
                    height="36"
                    @click="showInvite = false"
                  />
                  <h3 class="text-xl pr-5 font-medium text-gray-900 dark:text-white text-center">
                    YOUR GROUPS
                  </h3>

<!--                   <h3 class="mb-1 text-lg font-medium text-gray-900 dark:text-white">Invite friends</h3> -->
                  <!-- <span class="text-xs">*selecting a group will copy an invite link to your clipboard to share with your friends</span> -->
                  <!-- Dropdown with my groups - some way to invite to selected group -->
                  <div class="wrapper-button mt-5 mb-5" v-if="groups">
                    <select v-model="group" id="groups"
                      class="w-full float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block p-2.5 mr-5">
                      <option value="" disabled>Select a group</option>
                      <option v-for="g in Object.keys(groups)" :value="g" :key="g">
                        {{ groups[g].name }}
                      </option>
                    </select>
                  </div>

                  <button v-if="group"
                    class="mb-1 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="copy(group, groups[group].name)">
                    Copy invite link
                  </button>

                  <div class="mt-4 text-center">
                  Prefer a brand new group?
                  <p @click="showInvite = false" style="color: #07C157; cursor: pointer;" class="forgot-password">
                    Create group!
                  </p>
                </div>

                </div>


                <!--                 <button
                  class="mt-5 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  @click="login"
                >
                  Join group
                </button>
 -->
                <button v-if="!joinID && !showInvite"
                  class="mt-10 w-full bg-red-200 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  @click="logout">
                  Log out
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from "vue";
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { useClickOutside } from "@/composables/useClickOutside";
import { auth } from "../../firebaseDb";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: "ProfileDialog",
  props,
  components: {
    Icon,
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showProfile = ref(false);

    function closeModal() {
      showProfile.value = false;
    }

    onClickOutside(modal, () => {
      if (showProfile.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      (show) => {
        showProfile.value = true;
      }
    );

    return {
      closeModal,
      showProfile,
      modal,
    };
  },
  data() {
    return {
      showInvite: false,
      group: "",
      newgroup: "",
    };
  },
  methods: {
    logout() {
      auth.logout();
      this.$store.dispatch("cleanup");
      this.closeModal();
    },
    copy(g,n) {
      //console.log(g);
      let url = "https://eyedle.today/?join=" + g + "&name=" + n;
      navigator.clipboard.writeText(url);

      this.$notify(
        { group: "foo", title: this.$i18n.t("invite.copied"), text: this.$i18n.t("invite.sharetext") },
        2000
      ); // 2s

    },
  },
  computed: {
    ...mapGetters({
      groups: "groups",
      eyedleuser: "eyedleuser",
      loggedIn: "loggedIn",
      stats: "stats",
      joinID: "joinID",
      joinName: "joinName",
    }),
  },
};
</script>
