import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";

var appname = "Eyedle";

const routes = [
  // Routes
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: appname },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
