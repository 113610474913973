<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="showStats && stats"
        ref="modal-backdrop"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
      >
        <div class="flex items-start justify-center min-h-screen pt-20 text-center">
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              style="max-width: 400px"
              class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
              role="dialog"
              ref="modal"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
            <Icon
                  class="float-right"
                  icon="ri:close-circle-line"
                  color="gray"
                  style="margin-top: -25px; margin-right: -25px; cursor: pointer;"
                  height="36"
                  @click="closeModal"
                />

              <div v-if="eyedleuser && eyedleuser.loggedIn && groups && JSON.stringify(groups) !== '{}'">

                <div v-if="showStrikes">
                  <Icon
                    class="float-left pr-2"
                    icon="material-symbols:keyboard-backspace"
                    height="36"
                    @click="showStrikes = false"
                  />
                  <h3 class="text-xl pr-5 font-medium text-gray-900 dark:text-white text-center">
                    STRIKES
                  </h3>

                  <table class="w-full clear-both mt-5">
                    <tr v-for="s in Object.keys(strikes)" :key="s">
                      <td class="text-left p-1 pb-2">
                        {{ s }}
                      </td>
                      <td class="text-left p-1">
                        <Icon class="float-left pr-2 pb-2" icon="noto:fire" height="28" />
                        {{ strikes[s] }}
                      </td>
                    </tr>
                  </table>
                </div>

                <div v-else-if="showCorrect">
                  <Icon
                    class="float-left pr-2"
                    icon="material-symbols:keyboard-backspace"
                    height="36"
                    @click="showCorrect = false"
                  />
                  <h3 class="text-xl pr-5 font-medium text-gray-900 dark:text-white text-center">
                    WINS
                  </h3>

                  <table class="w-full clear-both mt-5">
                    <tr v-for="s in Object.keys(correct)" :key="s">
                      <td class="text-left p-1">
                        {{ s }}
                      </td>
                      <td class="text-left p-1">
                        {{ Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(correct[s]) }}
                      </td>
                    </tr>
                  </table>
                </div>

                <div v-else-if="showWrong">
                  <Icon
                    class="float-left pr-2"
                    icon="material-symbols:keyboard-backspace"
                    height="36"
                    @click="showWrong = false"
                  />
                  <h3 class="text-xl pr-5 font-medium text-gray-900 dark:text-white text-center">
                    FAILS
                  </h3>

                  <table class="w-full clear-both mt-5">
                    <tr v-for="s in Object.keys(wrong)" :key="s">
                      <td class="text-left p-1">
                        {{ s }}
                      </td>
                      <td class="text-left p-1">
                        {{ Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(wrong[s]) }}
                      </td>
                    </tr>
                  </table>
                </div>

                <div v-else-if="showFast">
                  <Icon
                    class="float-left pr-2"
                    icon="material-symbols:keyboard-backspace"
                    height="36"
                    @click="showFast = false"
                  />
                  <h3 class="text-xl pr-5 font-medium text-gray-900 dark:text-white text-center">
                    FASTEST
                  </h3>

                  <table class="w-full clear-both mt-5">
                    <tr>
                      <th></th>
                      <th>
                        <Icon
                        class="float-right mr-1"
                        icon="emojione-v1:lightning-mood"
                        height="32"
                      />
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th></th>
                      <th class="text-right">1st</th>
                      <th class="text-right">2nd</th>
                      <th class="text-right">3rd</th>
                    </tr>

                    <tr v-for="s in Object.keys(fast)" :key="s">
                      <td class="text-left p-1">
                        {{ s.split(" ")[0] }}
                      </td>
                      <td class="p-1 text-right">
                        {{ fast[s]["1"] }}
                      </td>
                      <td class="p-1 text-right">
                        {{ fast[s]["2"] }}
                      </td>
                      <td class="p-1 text-right">
                        {{ fast[s]["3"] }}
                      </td>

                    </tr>
                  </table>
                </div>

                <div v-else-if="groups">
                  <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">
                    HIGHSCORE
                  </h3>
                  <div class="wrapper-button mb-5">
                    <select
                      v-model="groupID"
                      id="groups"
                      class="w-full float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block p-2.5 mr-5"
                    >
                      <option v-for="g in Object.keys(groups)" :value="g" :key="g">
                        {{ groups[g].name }}
                      </option>
                    </select>
                  </div>

                  <div v-if="groupID" class="mt-5 w-full inline-block">
                    <div class="w-1/2 float-left" @click="showCorrect = true">
                      <Icon class="float-left pr-2 pt-1" icon="noto:partying-face" height="36" />
                      <div class="float-left text-sm text-gray-600">Top win %</div>
                      <br />
                      <div class="float-left text-sm">
                        {{ (groupstats.length > 0) ? groupstats[0].split(" ")[0] : "" }}
                      </div>
                    </div>
                    <div class="w-1/2 float-right mb-5" @click="showWrong = true">
                      <Icon
                        class="float-left pr-2 pt-1"
                        icon="mdi:emoticon-poop"
                        height="36"
                        color="brown"
                      />
                      <div class="float-left text-sm text-gray-600">Top fail %</div>
                      <br />
                      <div class="float-left text-sm">
                        {{ (groupstats.length > 0) ? groupstats[1].split(" ")[0] : "" }}
                      </div>
                    </div>
                    <div class="w-1/2 clear-both float-left" @click="showFast = true">
                      <Icon
                        class="float-left pr-2 pt-1"
                        icon="emojione-v1:lightning-mood"
                        height="36"
                      />
                      <div class="float-left text-sm text-gray-600">Top fast %</div>
                      <br />
                      <div class="float-left text-sm">
                        {{ groupstats[2].split(" ")[0] }}
                      </div>
                    </div>
                    <div class="w-1/2 float-right" @click="showStrikes = true">
                      <Icon class="float-left pr-2 pt-1" icon="noto:fire" height="36" />
                      <div class="float-left text-sm text-gray-600">Best strike</div>
                      <br />
                      <div class="float-left text-sm">
                        {{ groupstats[3].split(" ")[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <h3 class="mb-4 text-lg font-medium text-gray-900 dark:text-white text-left">
                      Today
                    </h3>

                    <table class="w-full clear-both mt-5">
                      <tr v-for="s in Object.keys(today)" :key="s">
                        <td class="text-left text-sm p-1">
                          {{ (s && s.length > 18) ? s.substring(0, 18) + "..." : s }}
                        </td>
                        <td class="text-left p-1">
                          {{ guessnr[today[s]] }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div v-else>
                <p class="mb-4 font-bold">{{ $t("stats.statistics") }}</p>
                <table>
                  <tr class="text-xs">
                    <th class="p-2">{{ $t("stats.played") }}</th>
                    <th class="p-2">{{ $t("stats.wins") }}</th>
                    <th class="p-2">{{ $t("stats.streak") }}</th>
                    <th class="p-2">{{ $t("stats.max") }}</th>
                  </tr>
                  <tr>
                    <td class="p-2">{{ stats.played }}</td>
                    <td class="p-2">
                      {{
                        stats.wins / stats.played
                          ? Intl.NumberFormat("da-DK", {
                              style: "percent",
                              maximumFractionDigits: 0,
                            }).format(stats.wins / stats.played)
                          : "0%"
                      }}
                    </td>
                    <td class="p-2">{{ stats.currStreak }}</td>
                    <td class="p-2">{{ stats.maxStreak }}</td>
                  </tr>
                </table>

                <p class="mb-4 mt-5 font-bold">{{ $t("stats.dist") }}</p>
                <table class="w-full">
                  <tr>
                    <td>{{ $t("stats.first") }}</td>
                    <td>{{ stats[1] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("stats.second") }}</td>
                    <td>{{ stats[2] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("stats.third") }}</td>
                    <td>{{ stats[3] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("stats.fourth") }}</td>
                    <td>{{ stats[4] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("stats.fifth") }}</td>
                    <td>{{ stats[5] }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from "vue";
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { useClickOutside } from "@/composables/useClickOutside";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: "StatsDialog",
  props,
  components: {
    Icon,
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showStats = ref(false);

    function closeModal() {
      showStats.value = false;
    }

    onClickOutside(modal, () => {
      if (showStats.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      (show) => {
        showStats.value = true;
      }
    );

    return {
      closeModal,
      showStats,
      modal,
    };
  },

  data() {
    return {
      groupID: "",
      showStrikes: false,
      showCorrect: false,
      showWrong: false,
      showFast: false,
      guessnr: {
        undefined: "⬜⬜⬜⬜⬜",
        "1": "🟩⬜⬜⬜⬜",
        "2": "🟥🟩⬜⬜⬜",
        "3": "🟥🟥🟩⬜⬜",
        "4": "🟥🟥🟥🟩⬜",
        "5": "🟥🟥🟥🟥🟩",
        "6": "🟥🟥🟥🟥🟥",
        "7": "⬜⬜⬜⬜⬜",
      },

    };
  },
  computed: {
    ...mapGetters({
      stats: "stats",
      groups: "groups",
      eyedleuser: "eyedleuser",
      date: "date",
    }),
    groupstats() {
      let bestCorrect = 0;
      let bestWrong = 0;
      let bestFast = 0;
      let bestStrike = 0;

      let mostCorrect = "";
      let mostWrong = "";
      let mostFast = "";
      let longestStrike = "";

      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          if (myGroup.stats[key].wins / myGroup.stats[key].played >= bestCorrect) {
            bestCorrect = myGroup.stats[key].wins / myGroup.stats[key].played;
            mostCorrect = key;
          }
          if (
            (myGroup.stats[key].played - myGroup.stats[key].wins) / myGroup.stats[key].played >=
            bestWrong
          ) {
            bestWrong =
              (myGroup.stats[key].played - myGroup.stats[key].wins) / myGroup.stats[key].played;
            mostWrong = key;
          }
          if (myGroup.stats[key]["1"] / myGroup.stats[key].played >= bestFast) {
            bestFast = myGroup.stats[key]["1"] / myGroup.stats[key].played;
            mostFast = key;
          }
          if (myGroup.stats[key].maxStreak >= bestStrike) {
            bestStrike = myGroup.stats[key].maxStreak;
            longestStrike = key;
          }
        });
        //console.log(mostCorrect, mostWrong, mostFast, longestStrike);
        return [
          myGroup.names[mostCorrect] || "",
          myGroup.names[mostWrong] || "",
          myGroup.names[mostFast] || "",
          myGroup.names[longestStrike] || "",
        ];
      } else {
        return ["","","",""];
      }
    },

    today() {
      let today = {};
      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          if (myGroup.stats[key].lastGame == this.date) {
            today[myGroup.names[key]] = myGroup.stats[key].lastScore || "7";
          } else {
            today[myGroup.names[key]] = "7";
          }
        });
      }

      const sortable = Object.fromEntries(Object.entries(today).sort(([, a], [, b]) => a - b));
      //console.log(sortable);
      return sortable;
    },

    strikes() {
      let strikes = {};
      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          strikes[myGroup.names[key]] = myGroup.stats[key].maxStreak;
        });
      }

      const sortable = Object.fromEntries(Object.entries(strikes).sort(([, a], [, b]) => b - a));
      //console.log(sortable);
      return sortable;
    },

    correct() {
      let correct = {};
      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          if (myGroup.stats[key].played > 0){
            correct[myGroup.names[key]] = myGroup.stats[key].wins / myGroup.stats[key].played;
          } else {
            correct[myGroup.names[key]] = 0;
          }
        });
      }

      const sortable = Object.fromEntries(Object.entries(correct).sort(([, a], [, b]) => b - a));
      return sortable;
    },

    wrong() {
      let wrong = {};
      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          if (myGroup.stats[key].played > 0){
            wrong[myGroup.names[key]] = (myGroup.stats[key].played - myGroup.stats[key].wins) / myGroup.stats[key].played;
          } else {
            wrong[myGroup.names[key]] = 0;
          }
        });
      }

      const sortable = Object.fromEntries(Object.entries(wrong).sort(([, a], [, b]) => b - a));
      return sortable;
    },

    fast() {
      let fast = {};
      if (this.groupID == "" && this.groups && Object.keys(this.groups).length > 0) {
        this.groupID = Object.keys(this.groups)[0];
      }

      if (this.groups && this.groupID) {
        let myGroup = this.groups[this.groupID];

        Object.keys(myGroup.stats).forEach((key) => {
          fast[myGroup.names[key]] = {};
          fast[myGroup.names[key]]["1"] = (myGroup.stats[key]["1"] > 0) ?
            Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(myGroup.stats[key]["1"] / myGroup.stats[key].played) : "-";
          fast[myGroup.names[key]]["compare"] = (myGroup.stats[key]["1"] > 0) ?
            (myGroup.stats[key]["1"] / myGroup.stats[key].played) : 0;
          fast[myGroup.names[key]]["2"] = (myGroup.stats[key]["1"] > 0) ? 
            Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(myGroup.stats[key]["2"] / myGroup.stats[key].played) : "-";
          fast[myGroup.names[key]]["3"] = (myGroup.stats[key]["1"] > 0) ?
            Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(myGroup.stats[key]["3"] / myGroup.stats[key].played) : "-";
        });
      }

      const sortable = Object.fromEntries(Object.entries(fast).sort(([, a], [, b]) => b["compare"] - a["compare"]));
      //console.log(sortable);
      return sortable;
    },


  },
};
</script>
