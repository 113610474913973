<template>
<!--    <vpr :onRefresh="onRefresh">
 -->  
 <div v-if="guesses" class="card px-2 py-2 rounded-md bg-white justify-center w-full grow"
  >
<!--   {{ $i18n.locale }}
 -->    <div class="card bg-white w-full">

      <div v-if="status != 'inprogress' && status!=''" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'.jpg'" alt="" />
      </div>

      <div v-else-if="!guesses[date] || guesses[date].length == 0" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'-6.jpg'" alt="" />
      </div>

      <div v-else-if="guesses[date].length == 1" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'-5.jpg'" alt="" />
      </div>

      <div v-else-if="guesses[date].length == 2" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'-4.jpg'" alt="" />
      </div>

      <div v-else-if="guesses[date].length == 3" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'-3.jpg'" alt="" />
      </div>

      <div v-else-if="guesses[date].length == 4" class="thumbnail">
          <img class="image rounded-lg" :src="'img/'+date+'-2.jpg'" alt="" />
      </div>

      <div v-else style="width: 100%">&nbsp;</div>

      <SimpleKeyboard v-if="!nativeKeyboard && status == 'inprogress'" @onChange="onChange" :input="input" />

      <div v-if="!nativeKeyboard && input && input.length>0" class="text-center mt-2">Searching for: {{ input.toUpperCase() }}</div>
 
      <input
        v-if="nativeKeyboard && status == 'inprogress'"
        ref="guessInput"
        :value="input"
        @focus="showKeyboard=false"
        @blur="showKeyboard=true"
        type="search"
        style="width: 90vw; height: 35px"
        class="input text-center mt-3 ml-3 mr-5"
        @input="onChangeNative"
        :placeholder="$t('general.typeor')"
      >

      <div v-if="status == 'inprogress'" class="mx-2 my-2">
        <ul v-if="searchWords && searchWords.length>0" class="p-1 relative z-10 h-1/4 overflow-auto">
          <li
            v-for="word in searchWords"
            :key="word"
            @click="selectCountry(word)"
            class="cursor-pointer rounded m-2 hover:bg-gray-100 p-1 bg-blue-200"
          >
<!--           {{ word }} -->
           <b>{{ word.slice(0,input.length) }}</b>{{ word.slice(input.length) }}
          </li>
        </ul>
        <ul v-else-if="input.length > 0"  class="p-1 relative z-10">
          <li class="cursor-pointer rounded m-2 hover:bg-gray-100 p-1 bg-blue-200">{{ $t("general.nomatch")}} '{{ input }}'</li>
        </ul>
      </div>
    </div>

    <div v-if="!guesses || !guesses[date] || guesses[date].length == 0">
      <h1 class="text-center pt-2 text-xl">{{ $t("general.who") }}</h1>
      <div class="flex text-center justify-center">
        <p class="pt-2 float-left">
          {{ $t("general.guesses") }}
          <Icon class="float-right mt-1" icon="la:smile-wink" height="20px" />
        </p>
      </div>
    </div>

    <div v-else-if="status == 'inprogress'">
      <h1 class="text-center pt-2 text-xl">{{ $t("general.tryagain") }}</h1>
    </div>

    <div v-else-if="status == 'failed'">
      <h1 class="text-center pt-2 text-xl">{{ $t("general.nomore") }}</h1>
      <p class="text-center pt-2">{{ $t("general.correct") }} {{ theanswer }}</p>
      <div class="flex text-center justify-center">
      <vue-countdown :time="time" :interval="100" v-slot="{ hours, minutes, seconds }">
        <p class="pt-4 float-left">{{ $t("general.next") }} {{ hours }}:{{ String(minutes).padStart(2, '0') }}:{{ String(seconds).padStart(2, '0') }}</p>
  </vue-countdown>
      </div>

      <div class="flex flex-wrap p-4 justify-center">
        <button @click="copy(6)" class="w-1/3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 mr-4 rounded inline-flex items-center">
          <Icon icon="bi:clipboard-check-fill" height="18"/>
          <span class="ml-2">{{ $t("general.share") }}</span>
      </button>

      <button class="w-1/3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded inline-flex items-center">
        <ShareNetwork
            network="facebook"
            title="EYEDLE"
            :url="(game == 'celeb') ? 'https://celeb.eyedle.today/?guess=6' : 'https://eyedle.today/?guess=6'"
          >
          <Icon class="float-left" icon="akar-icons:facebook-fill" height="18"/>
          <span class="float-right ml-2">{{ $t("general.share") }}</span>
        </ShareNetwork>
    </button>

      </div>

    </div>
    <div v-else-if="status && status.length == 1">
      <h1 v-if="status==1" class="text-center pt-10 text-xl font-bold">{{ $t("feedback.first") }}</h1>
      <h1 v-else-if="status==2" class="text-center pt-10 text-xl font-bold">{{ $t("feedback.second") }}</h1>
      <h1 v-else-if="status==3" class="text-center pt-10 text-xl font-bold">{{ $t("feedback.third") }}</h1>
      <h1 v-else-if="status==4" class="text-center pt-10 text-xl font-bold">{{ $t("feedback.fourth") }}</h1>
      <h1 v-else-if="status==5" class="text-center pt-10 text-xl font-bold">{{ $t("feedback.fifth") }}</h1>

      <p v-if="status==1" class="text-center pt-4 px-3">{{ $t("general.guessed") }} {{ theanswer }} {{ $t("guessed.first") }}</p>
      <p v-else-if="status==2" class="text-center pt-4 px-3">{{ $t("general.guessed") }} {{ theanswer }} {{ $t("guessed.second") }}</p>
      <p v-else-if="status==3" class="text-center pt-4 px-3">{{ $t("general.guessed") }} {{ theanswer }} {{ $t("guessed.third") }}</p>
      <p v-else-if="status==4" class="text-center pt-4 px-3">{{ $t("general.guessed") }} {{ theanswer }} {{ $t("guessed.fourth") }}</p>
      <p v-else-if="status==5" class="text-center pt-4 px-3">{{ $t("general.guessed") }} {{ theanswer }} {{ $t("guessed.fifth") }}</p>

      <div class="flex text-center justify-center">
        <vue-countdown :time="time" :interval="100" v-slot="{ hours, minutes, seconds }">
          <p class="pt-4 float-left">{{ $t("general.next") }} {{ hours }}:{{ String(minutes).padStart(2, '0') }}:{{ String(seconds).padStart(2, '0') }}</p>
        </vue-countdown>
      </div>

      <div class="flex flex-wrap p-4 justify-center">
        <button @click="copy(status)" class="w-1/3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 mr-4 rounded inline-flex items-center">
          <Icon icon="bi:clipboard-check-fill" height="18"/>
          <span class="ml-2">{{ $t("general.share") }}</span>
      </button>

      <button class="w-1/3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded inline-flex items-center">
        <ShareNetwork
            network="facebook"
            title="EYEDLE"
            :url="(game == 'celeb') ? 'https://celeb.eyedle.today/?guess='+status : 'https://eyedle.today/?guess='+status"
          >
          <Icon class="float-left" icon="akar-icons:facebook-fill" height="18"/>
          <span class="float-right ml-2">{{ $t("general.share") }}</span>
        </ShareNetwork>
    </button>

      </div>

    </div>
  </div>

  <footer v-if="guesses && guesses[date]" class="pb-safe w-full fixed bottom-0">
  <div class="flex p-2 float-right" @click="showGuess = !showGuess">
    <div
      class="px-2 h-6 rounded-full font-semibold flex items-center cursor-pointer"
      :class="`bg-gray-100 text-gray-700 hover:bg-gray-500 hover:text-white`"
    >
      {{ (guesses[date].length == 5) ? 5 : (status && status.length == 1) ? (guesses[date].length) : (guesses[date].length+1) }}/5
    </div>
  </div>
</footer>

<!-- </vpr>
 -->
  <GuessDialog :show="showGuess" :guesses="guesses[date]"></GuessDialog>

</template>

<script>
// @ is an alias to /src
import GuessDialog from "@/views/components/GuessDialog.vue";
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { ref } from "vue";
import SimpleKeyboard from "./components/SimpleKeyboard.vue";
//import vpr from "vue-pull-refresh";
//import { useMeta } from 'vue-meta';

export default {
  name: "Eydele",

  setup() {
    //var dateObj = new Date();
    //var day = ('0' + (dateObj.getDate())).slice(-2);
    //var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    //var year = dateObj.getFullYear();
    const showGuess = ref(false);
    return {
      showGuess,
    };

  },

  data() {
    return {
      now: new Date(),
      newYear: new Date().setHours(24,0,0,0),
      //zoom: 1,
      //guesses: [],
      //countries,
      input: ref(""),
      //searchCountries: [],
      //selectCountry,
      countries: [],
      answer: [],
      selectedCountry: ref(""),
      showKeyboard: true,
    };
  },
  components: {
    Icon,
    SimpleKeyboard,
    GuessDialog,
    //vpr
  },
  methods: {
/*     onRefresh: function() {
      //alert("hello! Type code here to run on this event");
      this.$store.dispatch("setAnswer", this.answer[this.date]);
      this.$store.dispatch("setStatus");

    },
 */    //doSearchCountries() {
      //console.log(this.input);
      //this.$store.dispatch("setSearchWords", this.input);

/*       //return [];
      if (this.input === "" ) {
        this.searchCountries = [];
      } else {
      let matches = 0;
      //console.log(words);

      let res = this.words.filter((country) => {
        let names = country.name.split(" ");
        let len = names.length; 
        if(len > 1) {
          if ((names[0].toLowerCase().startsWith(this.input) || names[len-1].toLowerCase().startsWith(this.input)) && matches < 50) {
            matches++;
            return country;
          } else if(country.name.toLowerCase().startsWith(this.input)) {
            matches++;
            return country;
          }
        } else {
        if (names[0].toLowerCase().startsWith(this.input) && matches < 50) {
          matches++;
          return country;
        }
      }
      });
      this.searchCountries = res;
    }
 */     
//},

    copy(guessNr) {
      let text = [
        this.$i18n.t("share.first"),
        this.$i18n.t("share.second"),
        this.$i18n.t("share.third"),
        this.$i18n.t("share.fourth"),
        this.$i18n.t("share.fifth"),
        this.$i18n.t("share.sixth"),
      ];
      navigator.clipboard.writeText(text[guessNr-1]);
      this.$notify(
        { group: "foo", title: this.$i18n.t("general.copied"), text: this.$i18n.t("general.sharetext") },
        2000
      ); // 2s
    },
    onChange(input) {
      this.input = input;
      this.$store.dispatch("setSearchWords", this.input);
    },
    onChangeNative(event) {
      this.input = event.target.value;
      //console.log(event)
      this.$store.dispatch("setSearchWords", this.input);
    },
    //onKeyPress(button) {
    //console.log("button", button);
    //},
/*     onInputChange(input) {
      console.log(input.target.value)
      this.input = input.target.value;
    },
 */   
   selectCountry(country) {
      this.selectedCountry = country;
      this.input = "";
      this.$store.commit("ADD_GUESS", country);
      this.$store.dispatch("setStatus");
      //this.guesses[this.date][this.zoom-1] = country;
      //this.zoom = this.zoom + 1;
    },
  },
  computed: {
    ...mapGetters({
      eyedleuser: "eyedleuser",
      stats: "stats",
      guesses: "guesses",
      date: "date",
      status: "status",
      game: "game",
      words: "words",
      theanswer: "answer",
      searchWords: "searchWords",
      nativeKeyboard: "nativeKeyboard",
    }),
    time() {
      return this.newYear - this.now;
    },
  },
  watch: {
    //date: function (newVal, oldVal) {
    //  console.log("Prop changed: ", newVal, " | was: ", oldVal);
    //},
  },
  mounted() {
     //console.log(this.$route.query.join);
      if (this.$route.query.join && this.$route.query.join.length > 0) {
        this.$store.dispatch("setJoinID", this.$route.query.join);
        this.$store.dispatch("setJoinName", this.$route.query.name);
      }
    //https://eyedle.today/?join=6Zwii5y5xyiEoR0brDpO
      
/*       const joingroup = {
        id: join,
        uid: this.eyedleuser,
        name: this.eyedleuser.data.name || this.eyedleuser.data.email,
        stats: this.stats
      };
      this.$store.dispatch("joinGroup", joingroup);
 */
  },
  async created() {
    await import("./data/"+this.game+"-answer.json").then((module) => {
      this.answer = module.default;
    });
    this.$store.dispatch("setAnswer", this.answer[this.date]);
    this.$store.dispatch("setStatus");

    await import("./data/" +this.game+ ".json").then((module) => {
      this.countries = module.default;
    });
    //console.log(this.game);
    this.$store.dispatch("setWords", this.countries);
  },
};
</script>

<style type="text/css">
 .simple-keyboard {
  max-width: 850px;
  margin: auto;
}

 .simple-keyboard .hg-button {
  -webkit-tap-highlight-color: rgba(201, 224, 253, 0.8);
/*     -webkit-tap-highlight-color: grey !important;
    -webkit-tap-highlight-color:transparent;
    -webkit-user-select: none;
 */ }

 html {
  -webkit-tap-highlight-color: rgba(201, 224, 253, 0.8);
}

.thumbnail {
  width: 85vw;
  /*max-height: 40vh;*/
  margin:auto;
  margin-bottom: 10px;
}
.image {
  max-width: 100%;
    max-height: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
    align-items: center
}

</style>
