<template>

<!--   <metainfo>
    <template v-slot:image="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>
 -->  <!-- App -->
 <div v-if="loading" class="flex justify-center items-center h-screen">
    <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
  </div>
  <div v-else class="justify-center items-center">
    <div class="overflow-auto h-screen flex flex-col justify-between">
      <Header />
      <router-view />
      <!--       <Footer />
 -->
    </div>

    <notificationGroup group="foo" position="bottom">
      <div class="z-20 fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-end justify-end">
        <div class="max-w-sm w-full">
          <notification v-slot="{notifications}">
            <div class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications" :key="notification.id">
              <div class="flex justify-center items-center w-12 bg-green-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-green-500 font-semibold">{{notification.title}}</span>
                  <p class="text-gray-600 text-sm">{{notification.text}}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <notificationGroup group="error" position="bottom">
      <div class="z-20 fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-end justify-end">
        <div class="max-w-sm w-full">
          <notification v-slot="{notifications}">
            <div class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications" :key="notification.id">
              <div class="flex justify-center items-center w-12 bg-red-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-red-500 font-semibold">{{notification.title}}</span>
                  <p class="text-gray-600 text-sm">{{notification.text}}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

  </div>
</template>

<script>
// vue Components
//import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { auth, analytics } from "./firebaseDb";
import { mapGetters } from "vuex";
//import HelpModal from "@/views/HelpModal.vue";

export default {
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Header,
    Footer,
  },

  mounted: function () {
    this.$store.dispatch("setGuesses")
    this.$store.dispatch("setStats")

    auth.auth.onAuthStateChanged((user) => {
      if (!user) {
        //this.$store.dispatch("setStatus");
        this.loading = false;
      }
      //TODO perhaps check store if this is loaded already from main.js and not call again?
      this.$store.dispatch("fetchUser", user).then((eyedleuser) => {
        if (user && user.uid) {
          this.$store.dispatch("setGuesses")
          this.$store.dispatch("setStatus")
          this.$store.dispatch("setGroups")
          this.$store.dispatch("setStats")
          //this.$store.dispatch("setStatus");
          //console.log(eyedleuser);
          this.loading = false;
          analytics.setUserId(eyedleuser.uid);
          analytics.setUserProperties({
            account_email: eyedleuser.email,
            account_name:  eyedleuser.name,
          });
        }
      })
    });

    //Scrollbar.init(document.querySelector("#body-scroll"));
    //if(window && window.location && window.location.hostname && window.location.hostname == "localhost") {
    //  this.$store.commit("SET_GAME", "da");
    //  this.$i18n.locale = "da";
    //}

    //if (window && window.location && window.location.hostname && (window.location.hostname == "celeb.eyedle.today" || window.location.hostname == "localhost")) {
    if (window && window.location && window.location.hostname && window.location.hostname == "celeb.eyedle.today") {
      this.$i18n.locale = "celeb";
      this.$store.commit("SET_GAME", this.$i18n.locale);
    } else {
      //let preferredLocale = this.locale;
      //if(preferredLocale != "" && ["en", "da", "is"].includes(preferredLocale)){
      //  this.$i18n.locale = preferredLocale;
      //  this.$store.commit("SET_GAME", preferredLocale);
      //} else if (["en", "da", "is"].includes(this.$i18n.locale)) {
      //  this.$store.commit("SET_GAME", this.$i18n.locale);
      //} else {
        this.$i18n.locale = "en";
        this.$store.commit("SET_GAME", this.$i18n.locale);
      //}
    }
  },

  computed: {
    ...mapGetters({
      locale: "locale",
      //client: "client",
      //clientData: "clientData"
    }),
  },
};
</script>
