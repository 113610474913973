<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0">
    <div v-show="showModal"
      ref="modal-backdrop"
      class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
    >
      <div
        class="flex items-start justify-center min-h-screen pt-20 text-center"
      >
      <transition
  enter-active-class="transition ease-out duration-300 transform "
  enter-from-class="opacity-0 translate-y-10 scale-95"
  enter-to-class="opacity-100 translate-y-0 scale-100"
  leave-active-class="ease-in duration-200"
  leave-from-class="opacity-100 translate-y-0 scale-100"
  leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
>
        <div
          style="max-width: 400px"
          class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
          role="dialog"
          ref="modal"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
        <Icon
                  class="float-right"
                  icon="ri:close-circle-line"
                  color="gray"
                  style="margin-top: -25px; margin-right: -25px; cursor: pointer;"
                  height="36"
                  @click="closeModal"
                />

<!--          <button class="absolute top-4 right-4">
            <Icon icon="ei:close" @click="closeModal" />
         </button>
 -->         
       <p class="mb-4 font-bold">{{ $t("help.how") }}</p>
       <p class="mb-4">{{ $t("help.one") }}</p>
       <p class="mb-4">{{ $t("help.two") }}</p>
       <p class="mb-4">{{ $t("help.three") }}</p>
       <p class="mb-4">{{ $t("help.four") }}</p>
       <p class="mb-4">{{ $t("help.five") }}</p>

        </div>
        </transition>
      </div>
    </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from 'vue';
import { Icon } from "@iconify/vue";
import { useClickOutside } from "@/composables/useClickOutside"

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: 'ModalDialog',
  props,
  components: {
    Icon
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showModal = ref(false);

    function closeModal() {
        showModal.value = false;
    }

    onClickOutside(modal, () => {
        if (showModal.value === true) {
        closeModal();
        }
    });

  watch(
    () => props.show,
    show => {
      showModal.value = true;
    },
  );

  return {
    closeModal,
    showModal,
    modal
  };
},
};
</script>