<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0">
    <div v-show="showGuess"
      ref="modal-backdrop"
      class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
    >
      <div
        class="flex items-start justify-center min-h-screen pt-96 text-center"
      >
      <transition
  enter-active-class="transition ease-out duration-300 transform "
  enter-from-class="opacity-0 translate-y-10 scale-95"
  enter-to-class="opacity-100 translate-y-0 scale-100"
  leave-active-class="ease-in duration-200"
  leave-from-class="opacity-100 translate-y-0 scale-100"
  leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
>
        <div
          class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
          role="dialog"
          ref="modal"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
        <!--          <button class="absolute top-4 right-4">
                    <Icon icon="ei:close" @click="closeModal" />
                </button>
        -->         
         <!--<slot>I'm empty inside</slot>-->

                  
   <table class="w-full px-5 pt-2">
          <tr>
            <td class="text-center p-1">
              {{ (guesses && guesses.length > 0) ? guesses[0] : 'Guess 1'}}
            </td>
          </tr>
            <tr>
            <td class="text-center p-1">
              {{ (guesses && guesses.length > 1) ? guesses[1] : 'Guess 2'}}
            </td>
          </tr>
          <tr>
          <td class="text-center p-1">
            {{ (guesses && guesses.length > 2) ? guesses[2] : 'Guess 3'}}
          </td>
          </tr>
          <tr>
          <td class="text-center p-1">
            {{ (guesses && guesses.length > 3) ? guesses[3] : 'Guess 4'}}
          </td>
          </tr>
          <tr>
          <td class="text-center p-1">
            {{ (guesses && guesses.length > 4) ? guesses[4] : 'Guess 5'}}
          </td>
          </tr>
        </table>
 
        </div>
        </transition>
      </div>
    </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from 'vue';
import { Icon } from "@iconify/vue";
import { useClickOutside } from "@/composables/useClickOutside"

const props = {
  show: {
    type: Boolean,
    default: false,
  },
  guesses: {
    type: Array,
    default: [],
  }, 
};
export default {
  name: 'GuessDialog',
  props,
  components: {
    Icon
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showGuess = ref(false);

    function closeModal() {
        showGuess.value = false;
    }

    onClickOutside(modal, () => {
        if (showGuess.value === true) {
        closeModal();
        }
    });

  watch(
    () => props.show,
    show => {
      showGuess.value = true;
    },
  );

  return {
    closeModal,
    showGuess,
    modal
  };
  },

   computed: {
    //...mapGetters({
      //guesses: "guesses",
      //date: "date",
      //status:"status"
    //  }),
    },
};
</script>