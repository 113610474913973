<template>
  <div>
    <footer class="w-full box-border px-5 text-center">
      <div class="flex text-center justify-center">
        <p class="pt-4 float-left">Like Eyedle? - Buy me a  
          <Icon class="float-right ml-1" icon="noto:beer-mug" height="26px"/>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
    data() {
      return {
      };
    },
    components: {
      Icon,
    },
  };

</script>