import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Icon } from "@iconify/vue";
import store from "./store/store";
//import "flowbite";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/windzo.css";
import VueCountdown from '@chenfengyuan/vue-countdown';
import Notifications from 'vue3-vt-notifications'
import { i18n } from "./i18n";
import VueSocialSharing from 'vue-social-sharing'
//import { createMetaManager } from 'vue-meta'
 
const app = createApp(App);
app.use(router, Icon);
app.use(store);
app.use(i18n);
app.use(VueSocialSharing);
app.use(Notifications);
//app.use(createMetaManager());

app.component(VueCountdown.name, VueCountdown);
app.mount("#app");

//router.beforeEach((to, from, next) => {
  //document.querySelector(".flex-sidebar").classList.add("hidden");
  //next();
//});
