<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="showSettings"
        ref="modal-backdrop"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
      >
        <div class="flex items-start justify-center min-h-screen pt-20 text-center">
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
              role="dialog"
              ref="modal"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
            <Icon
                  class="float-right"
                  icon="ri:close-circle-line"
                  color="gray"
                  style="margin-top: -25px; margin-right: -25px; cursor: pointer;"
                  height="36"
                  @click="closeModal"
                />

                <div class="mt-5">
    <label for="toggle" class="flex float-right cursor-pointer">
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input type="checkbox" name="toggle" id="toggle" class="sr-only" v-model="nativeKeyboard">
      <!-- line -->
      <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
      <!-- dot -->
      <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
    </div>
  </label>

    <!-- label -->
    <div class="float-left mb-4 font-bold">
      Use native keyboard
    </div>
  </div>

              <!--<slot>I'm empty inside</slot>-->
              <!-- p class="mb-4 font-bold">Native Keyboard</p> -->
<!--               <div class="block float-right inline-block w-10 align-middle select-none transition duration-200 ease-in" style="color: #07C157">
    <input type="checkbox" v-model="nativeKeyboard" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
    <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-200 cursor-pointer"></label>
</div>
<label for="toggle" class="float-left mb-4 font-bold">Use native keyboard</label>
 -->
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from "vue";
import { Icon } from "@iconify/vue";
import { useClickOutside } from "@/composables/useClickOutside";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: "SettingsDialog",
  props,
  components: {
    Icon,
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showSettings = ref(false);

    function closeModal() {
      showSettings.value = false;
    }

    onClickOutside(modal, () => {
      if (showSettings.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      (show) => {
        showSettings.value = true;
      }
    );

    return {
      closeModal,
      showSettings,
      modal,
    };
  },
  computed: {
    nativeKeyboard: {
      get() {
        return this.$store.state.nativeKeyboard;
      },
      set(value) {
        this.$store.dispatch("setNativeKeyboard", value);
      },
    },
  },
};
</script>

<style>

/* .toggle-checkbox:checked {
   @apply: right-62px;
  right: 62px;
   background-color: papayawhip;
}
 */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>