<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      //onKeyPress: this.onKeyPress,
      layout: {
        default: (this.$i18n.locale == "is") ?
          [
            "á é í ó ú ý æ ö þ ð",
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "z x c v b n m {space} {backspace}",
          ] : (this.$i18n.locale == "da") ?
            [
              "q w e r t y u i o p å",
              "a s d f g h j k l æ ø",
              "z x c v b n m {space} {backspace}",
            ] :
            [
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "z x c v b n m {space} {backspace}",
            ]
        ,
      },
      display: {
        "{backspace}": "⌫",
        "{space}": "space"
      }
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
/*     onKeyPress(button) {
      this.$emit("onKeyPress", button);

      //If you want to handle the shift and caps lock buttons
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
 */  
},
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
    '$i18n.locale': function (newVal, oldVal) {
      console.log('locale change', newVal);
      this.keyboard.setOptions({
        layout: {
          default: (newVal == "is") ?
            [
              "á é í ó ú ý æ ö þ ð",
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "z x c v b n m {space} {backspace}",
            ] : (newVal == "da") ?
              [
                "q w e r t y u i o p å",
                "a s d f g h j k l æ ø",
                "z x c v b n m {space} {backspace}",
              ] :
              [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "z x c v b n m {space} {backspace}",
              ]
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
